/*
 * This file is part of the Sylius package.
 *
 * (c) Paweł Jędrzejewski
 *
 * For the full copyright and license information, please view the LICENSE
 * file that was distributed with this source code.
 */

(function ( $ ) {
    'use strict';

    $.fn.extend({
        variantPrices: function () {
            let $formuuid = $(this).data('formuuid');
            let $productuuid = $(this).data('uuid');
            if ($('.variant-pricing-wrapper-'+$formuuid).length > 0) {
                handleProductOptionsChange($formuuid,$productuuid);
            } else if ($("#sylius-product-variants").length > 0) {
                handleProductVariantsChange($formuuid,$productuuid);
            }
        }
    });
})( jQuery );


function handleProductOptionsChange(formUuid,productUuid) {
    $(document).on('change','#sylius-product-adding-to-cart-'+formUuid+' [name*="sylius_add_to_cart[cartItem][variant]"]', function() {
        let gravitaConainter = document.getElementById('gravita-product-variants-availability');
        let availabilityMessage = document.getElementById('gravita-current-availability-message');
        let variantInput = document.getElementsByName('gravita[variant-id]');

        let $selector = '';
        $('#sylius-product-adding-to-cart-'+formUuid+' select[data-option]').each(function() {
            let option = $(this).find('option:selected').val();
            $selector += '[data-' + $(this).attr('data-option') + '="' + option + '"]';
        });

        let el = $('.variant-pricing-wrapper-'+formUuid+' .sylius-variants-pricing').find($selector);
        let $price = el.attr('data-value');
        let $originalPrice = el.attr('data-original-value');
        let $images = el.attr('data-images');
        let $isAvailable = el.attr('data-is-available');
        let $pvid = el.attr('data-pvid');

        if ($price !== undefined && $isAvailable === "1"){
            $('.product-price-'+productUuid+' .price').html($price);
            $('.product-price-'+productUuid+' .original-price').html($originalPrice);
            let $submitbtn = $('#sylius-product-adding-to-cart-'+formUuid+' button[type=submit]');
            $submitbtn.html($submitbtn.data('html-base'));
            $submitbtn.removeAttr('disabled');
            $submitbtn.data('options-selected',"1");
            $submitbtn.removeClass('not-available');
            gravitaConainter.style.display = 'none';
            $('.bonus-point-info').removeClass('hide');
            $('.cms-block_shipping_delay_info-block').removeClass('hide');
            $('.product-status').removeClass('hide');


        } else {
            let $submitbtn = $('#sylius-product-adding-to-cart-'+formUuid+' button[type=submit]');
            $submitbtn.attr('disabled', 'disabled');
            $submitbtn.html(el.parent().attr('data-unavailable-text'));
            gravitaConainter.style.display = 'block';
            $submitbtn.addClass('not-available');
            variantInput[0].value = $pvid;
            $('.bonus-point-info').addClass('hide');
            $('.cms-block_shipping_delay_info-block').addClass('hide');
            $('.product-status').addClass('hide');
        }

        Product.handleImageChange(formUuid,productUuid,$images);
    });
}


function handleProductVariantsChange(formUuid,productUuid) {

    let $submitbtn = $('#sylius-product-adding-to-cart-'+formUuid+' button[type=submit]');

    $('[name="sylius_add_to_cart[cartItem][variant]"]').on('change', function() {
        let $price = $(this).parents('tr').find('.sylius-product-variant-price').text();
        $('#product-price').text($price);
        $submitbtn.data('options-selected',"1");
    });
}
