/*
 * This file is part of the Sylius package.
 *
 * (c) Paweł Jędrzejewski
 *
 * For the full copyright and license information, please view the LICENSE
 * file that was distributed with this source code.
 */

(function ( $ ) {
    'use strict';

    $.fn.extend({
        updateCart: function () {
            var element = $(this);
            var $closeModal =  element.data('close-modal');
            var href = $(element).attr('action');
            var redirectUrl = $(element).data('redirect');
            $(element).api({
                method: 'POST',
                on: 'submit',
                cache: false,
                url: href,
                beforeSend: function (settings) {
                    settings.data = $(this).serialize();
                    $(this).find('#sylius-cart-update').html('<i class="waiting-icon fa fa-spinner fa-pulse fa-3x fa-fw margin-bottom"></i>');
                    return settings;
                },
                onSuccess: function (response) {
                    $(".content-grid-container").load(window.location.href + " .content-grid-container .content-wrapper", function(){
                        Cart.refreshCart(response);
                        Checkout.initAddress();
                        //Checkout.initToggler();
                        Checkout.initFormUpdates();
                        $('[data-toggles]').toggleElement();
                        $('.ui.checkbox').checkbox();
                        $('#page-modal').foundation('close');
                        new pickupClass({url:$('.cart-form-wrapper').attr('data-pick-up-url') });
                    });

                },
                onFailure: function (response) {

                    $(".content-grid-container").load(window.location.href + " .content-grid-container .content-wrapper", function(){
                        Cart.refreshCart(response);
                        Checkout.initAddress();
                       // Checkout.initToggler();
                        Checkout.initFormUpdates();
                        $('[data-toggles]').toggleElement();
                        $('.ui.checkbox').checkbox();
                        $('#page-modal').foundation('close');
                    });
                   /* validationElement.removeClass('hidden');
                    var validationMessage = '';

                    $.each(response.errors.errors, function (key, message) {
                        validationMessage += message;
                    });
                    validationElement.html(validationMessage);*/
                    $(element).removeClass('loading');
                },
            });
        }
    });
})( jQuery );
