/*
 * This file is part of the Sylius package.
 *
 * (c) Paweł Jędrzejewski
 *
 * For the full copyright and license information, please view the LICENSE
 * file that was distributed with this source code.
 */


(function ($) {
  $(document).ready(function () {
    $('.star.rating').rating({
      fireOnInit: true,
      onRate: function (value) {
        $("[name='sylius_product_review[rating]']:checked").removeAttr('checked');
        $("#sylius_product_review_rating_" + (value - 1)).attr('checked', 'checked');
      }
    });

    $('#sylius_checkout_address_customer_email').apiToggle({
      dataType: 'json',
      method: 'GET',
      throttle: 1500,

      beforeSend: function (settings) {
        var email = $('#sylius_checkout_address_customer_email').val();

        if (email.length < 3) {
          return false;
        }

        settings.data = {
          email: email
        };

        return settings;
      },

      successTest: function (response) {
        return $('#sylius_checkout_address_customer_email').val() === response.username;
      }
    }, $('#sylius-api-login-form'));

    $('#sylius-api-login').apiLogin({
      method: 'POST',
      throttle: 500
    });

    $('#sylius-summary-cart-form').updateCart();
    $('.sylius-cart-remove-button').removeFromCart();
    //$('#sylius-product-adding-to-cart-9').addToCart();

    $(document).provinceField();
    //$(document).variantPrices();
    //$(document).variantImages();


    // $('#sidebar').addClass('visible');
    $('#sidebar').sidebar('attach events', '#sidebar-toggle', 'toggle');
    $('#sidebar').sidebar('setting', {
      dimPage: false,
      closable: false
    });

    $('.ui.checkbox').checkbox();

    $('.ui.accordion').accordion();
    $('.ui.menu .dropdown').dropdown({
      action: 'hide'
    });
    $('.ui.inline.dropdown').dropdown();
    $('.link.ui.dropdown').dropdown({
      action: 'hide'
    });
    $('.button.ui.dropdown').dropdown({
      action: 'hide'
    });
    $('.ui.fluid.search.selection.ui.dropdown').dropdown();
    $('.menu .item').tab();
    $('.card .image').dimmer({
      on: 'hover'
    });
    $('.ui.rating').rating('disable');

    $('form.loadable button').on('click', function () {
      return $(this).closest('form').addClass('loading');
    });
    $('.loadable.button').on('click', function () {
      return $(this).addClass('loading');
    });
    $('.message .close').on('click', function () {
      return $(this).closest('.message').transition('fade');
    });

    $('[data-requires-confirmation]').requireConfirmation();
    $('[data-toggles]').toggleElement();

    $('.special.cards .image').dimmer({
      on: 'hover'
    });

    $('[data-form-type="collection"]').CollectionForm();
  });
})(jQuery);