/*
 * This file is part of the Sylius package.
 *
 * (c) Paweł Jędrzejewski
 *
 * For the full copyright and license information, please view the LICENSE
 * file that was distributed with this source code.
 */

(function ( $ ) {
    'use strict';

    $.fn.extend({
        addToCart: function () {
            var element = $(this);
            var $formuuid = element.data('formuuid');
            var $closeModal =  element.data('close-modal');
            var href = $(element).attr('action');
            var redirectUrl = $(element).data('redirect');
            var validationElement = $('#sylius-cart-validation-error-'+$formuuid);
            var successElement = $('#sylius-cart-validation-success-'+$formuuid);

                $(element).api({
                    method: 'POST',
                    on: 'submit',
                    cache: false,
                    url: href,
                    beforeSend: function(settings){
                        settings.data = $(this).serialize();
                        return settings;
                    },
                    beforeXHR: function(xhr){
                        if(element.find('.submit-btn').data('options-selected') === 0 ){
                            let message = element.find('.submit-btn').data('not-selected-option-error-message');

                            xhr.abort();
                            validationElement.removeClass('hidden');
                            var validationMessage = '';


                            validationMessage += message;
                            validationElement.html(validationMessage);
                            $(element).removeClass('loading');
                            setTimeout(function(){ validationElement.addClass('hidden'); }, 5000);

                        }
                    },
                    onSuccess: function (response) {

                        validationElement.addClass('hidden');
                        successElement.removeClass('hidden');
                        successElement.html(response.message);
                        setTimeout(function(){ successElement.addClass('hidden'); }, 5000);
                        Cart.refreshCart(response);
                        if($closeModal === 1){
                            Modal.closeModal();
                        }

                        Cart.showCartPopup();
                        new pickupClass({url:$('.cart-form-wrapper').attr('data-pick-up-url') });

                        enhancedEcommerceAddToCart(gtmAddToCartProductInfo);
                        // window.location.replace(redirectUrl);
                        // $('.flash-grid-container').load(window.location.href + ' .flash-grid-container .content-wrapper');
                    },
                    onFailure: function (response) {
                        validationElement.removeClass('hidden');
                        var validationMessage = '';

                        $.each(response.errors.errors, function (key, message) {
                            validationMessage += message;
                        });
                        validationElement.html(validationMessage);

                        $(element).removeClass('loading');
                        setTimeout(function(){ validationElement.addClass('hidden'); }, 5000);

                    },
                });
        }
    });
})( jQuery );
